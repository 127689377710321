<template>
  <div class="content-wrapper">
    <div class="panel">
      <h2 class="panel-heading text-transform-none spacing-0">
        {{ $t('upload') }}
      </h2>
      <div v-if="newUploadError" class="panel-block">
        <BMessage type="is-danger" has-icon icon-size="is-small">
          {{ $t('error-cannot-upload') }}
        </BMessage>
      </div>
      <div v-else class="panel-block">
        <BMessage type="is-info" has-icon icon-size="is-small">
          <h3>{{ $t('important-notes') }}</h3>
          <ul class="size-12 list-disc">
            <li>
              {{
                $t('drag-drop-upload-info', { labelButton: $t('add-files') })
              }}
            </li>
            <li>{{ $t('allowed-formats-upload-info') }}</li>
            <li>{{ $t('vms-mrxs-upload-info') }}</li>
            <li>{{ $t('max-size-upload-info') }}</li>
            <!-- <li>{{ $t("zip-upload-info") }}</li> -->
            <!-- <li>{{ $t("link-to-project-upload-info") }}</li> -->
          </ul>
        </BMessage>

        <div v-if="currentUser.adminByNow" class="columns align-center">
          <div class="column is-one-quarter has-text-right">
            <strong>{{ $t('storage') }}</strong>
          </div>
          <div class="column is-half">
            <CytomineMultiselect
              v-model="selectedStorage"
              :options="storages"
              :allow-empty="false"
              label="name"
              track-by="id"
            />
          </div>
        </div>

        <div class="columns align-center">
          <div class="column is-one-quarter has-text-right">
            <strong>{{ $t('link-with-project') }}</strong>
          </div>
          <div class="column is-half">
            <CytomineMultiselect
              v-model="selectedProject"
              :options="projects"
              label="name"
              track-by="id"
            />
          </div>
        </div>

        <div class="columns align-center">
          <div class="column is-one-quarter has-text-right">
            <strong>{{ $t('files') }}</strong>
          </div>
          <div class="column is-half">
            <div
              v-for="(wrapper, idx) in dropFiles"
              :key="idx"
              :class="[
                'grid align-center gap-16',
                { 'mt-2 border-t border-gray-2 pt-2': idx > 0 },
              ]"
              style="grid-template-columns: 30% 8ch 1fr auto"
            >
              <div class="word-break">
                {{ wrapper.file.name }}
              </div>
              <div class="min-w-max-content">
                {{ filesize(wrapper.file.size) }}
              </div>
              <template v-if="wrapper.uploadedFile === null">
                <progress
                  :value="wrapper.progress"
                  class="progress is-info flex-grow m-0"
                  max="100"
                >
                  {{ wrapper.progress }}%
                </progress>
                <div class="flex gap-12">
                  <IdxBtn color="primary" @click="startUpload(wrapper)">
                    {{ $t('button-start') }}
                  </IdxBtn>
                  <IdxBtn @click="cancelUpload(idx)">
                    {{ $t('cancel') }}
                  </IdxBtn>
                </div>
              </template>
              <template v-else>
                <div>
                  <UploadedFileStatus
                    v-if="wrapper.uploadedFile"
                    :file="wrapper.uploadedFile"
                  />
                  <span v-else class="tag is-danger">
                    {{ $t('upload-error') }}
                  </span>
                </div>
                <IdxBtn @click="cancelUpload(idx)">
                  {{ $t('button-hide') }}
                </IdxBtn>
              </template>
            </div>
            <em v-if="dropFiles.length < 1" class="color-gray-4">{{
              $t('no-file')
            }}</em>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half flex-column is-offset-one-quarter">
            <progress
              v-if="ongoingUpload"
              :value="overallProgress"
              class="progress is-success"
              max="100"
            >
              {{ overallProgress }}%
            </progress>

            <div class="flex align-center gap-8 flex-wrap">
              <BUpload
                :value="plainFiles"
                type="is-link"
                multiple
                drag-drop
                @input="filesChange"
              >
                <span class="button is-success m-0">{{ $t('add-files') }}</span>
              </BUpload>
              <IdxBtn
                :disabled="!filesPendingUpload"
                color="primary"
                @click="startAll()"
              >
                {{ $t('start-upload') }}
              </IdxBtn>
              <IdxBtn
                :disabled="!filesPendingUpload && !ongoingUpload"
                @click="cancelAll()"
              >
                {{ $t('cancel-upload') }}
              </IdxBtn>
              <IdxBtn v-if="filesFinishedUpload" @click="hideFinished">
                {{ $t('hide-successful-upload') }}
              </IdxBtn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel">
      <h2 class="panel-heading text-transform-none spacing-0">
        {{ $t('uploads') }}
      </h2>
      <div class="panel-block">
        <BInput
          :value="searchString"
          :placeholder="$t('search')"
          class="max-w-400"
          icon="search"
          @input="debounceSearchString"
        />

        <CytomineTable
          :collection="uploadedFileCollection"
          :revision="revision"
          :refresh-interval="tableRefreshInterval"
          sort="created"
          order="desc"
        >
          <template #default>
            <BTableColumn v-slot="props" :label="$t('preview')" width="80">
              <img
                v-if="props.row.thumbURL"
                :src="props.row.thumbURL"
                alt="-"
                class="max-h-64 max-w-96"
              />
              <div v-else class="is-size-7 has-text-grey">
                {{ $t('no-preview-available') }}
              </div>
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('filename')"
              field="originalFilename"
              sortable
              width="200"
            >
              {{ props.row.originalFilename }}
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('created')"
              field="created"
              sortable
              width="150"
            >
              {{ Number(props.row.created) | date('lll') }}
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('size')"
              field="size"
              sortable
              width="80"
            >
              {{ filesize(props.row.size) }}
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('content-type')"
              field="contentType"
              sortable
              width="100"
            >
              {{ props.row.contentType }}
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('global-size')"
              field="globalSize"
              sortable
              width="80"
            >
              {{ filesize(props.row.globalSize) }}
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('status')"
              field="status"
              sortable
              width="80"
            >
              <UploadedFileStatus :file="props.row" />
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('from')"
              field="parentFilename"
              sortable
              width="150"
            >
              {{ uFile.parentFilename ? uFile.parentFilename : '-' }}
            </BTableColumn>
          </template>

          <template #detail="{ row: uFile }">
            <UploadedFileDetails
              :key="uFile.id"
              :file="uFile"
              @update="updatedTree"
            />
          </template>

          <template #empty>
            <p>{{ $t('no-uploaded-file') }}</p>
          </template>
        </CytomineTable>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Cytomine,
  StorageCollection,
  ProjectCollection,
  UploadedFileCollection,
  UploadedFile,
} from 'cytomine-client';
import axios from 'axios';
import filesize from 'filesize';
import debounce from 'lodash/debounce.js';
import UploadedFileStatus from './UploadedFileStatus.vue';
import UploadedFileDetails from './UploadedFileDetails.vue';
import { UPLOADED_FILE_STATUS } from './index.js';
import constants from '@/utils/constants.js';

import CytomineMultiselect from '@/components/form/CytomineMultiselect.vue';
import CytomineTable from '@/components/utils/CytomineTable.vue';

export default {
  name: 'CytomineStorage',
  components: {
    CytomineMultiselect,
    UploadedFileStatus,
    UploadedFileDetails,
    CytomineTable,
  },
  data() {
    return {
      loading: true,
      newUploadError: false,
      timeoutRefreshSessionUploads: null,
      tableRefreshInterval: constants.STORAGE_REFRESH_INTERVAL,

      storages: [],
      selectedStorage: null,
      projects: [],
      selectedProject: null,

      searchString: '',

      dropFiles: [],

      signature: '',
      signatureDate: '',

      revision: 0,
    };
  },
  computed: {
    /** @returns {{ id: number, publicKey: string, adminByNow: boolean}} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    finishedStatus() {
      return [UPLOADED_FILE_STATUS.CONVERTED, UPLOADED_FILE_STATUS.DEPLOYED];
    },
    /** @returns {boolean} */
    ongoingUpload() {
      return this.dropFiles.some((wrapper) => wrapper.uploading);
    },
    /** @returns {boolean} */
    filesPendingUpload() {
      return this.dropFiles.some(
        (wrapper) => !wrapper.uploading && wrapper.uploadedFile === null
      );
    },
    /** @returns {boolean} */
    filesFinishedUpload() {
      return this.dropFiles.some(
        (wrapper) =>
          !wrapper.uploading &&
          wrapper.uploadedFile !== null &&
          this.finishedStatus.includes(wrapper.uploadedFile.status)
      );
    },
    /** @returns {number} */
    overallProgress() {
      let nbUploads = 0;
      let totalProgress = 0;
      this.dropFiles.forEach((wrapper) => {
        if (wrapper.uploading) {
          nbUploads++;
          totalProgress += wrapper.progress;
        }
      });
      return Math.floor(totalProgress / nbUploads);
    },
    uri() {
      return '/upload';
    },
    queryString() {
      let str = `cytomine=${constants.CYTOMINE_CORE_HOST}`;
      if (this.selectedStorage) {
        str += `&idStorage=${this.selectedStorage.id}`;
      }
      if (this.selectedProject) {
        str += `&idProject=${this.selectedProject.id}`;
      }
      return str;
    },
    /** @returns {any[]} */
    plainFiles() {
      return this.dropFiles.map((wrapper) => wrapper.file);
    },
    /** @returns {UploadedFileCollection} */
    uploadedFileCollection() {
      return new UploadedFileCollection({
        detailed: true,
        originalFilename: { ilike: encodeURIComponent(this.searchString) },
      });
    },
  },
  watch: {
    async queryString() {
      this.signatureDate = new Date().toISOString();
      try {
        this.signature = await Cytomine.instance.fetchSignature({
          uri: this.uri,
          queryString: this.queryString,
          method: 'POST',
          date: this.signatureDate,
        });
      } catch (error) {
        this.newUploadError = true;
      }
    },
  },
  activated() {
    this.fetchStorages();
    this.fetchProjects();
    this.refreshStatusSessionUploads();
    this.tableRefreshInterval = constants.STORAGE_REFRESH_INTERVAL;
  },
  deactivated() {
    clearTimeout(this.timeoutRefreshSessionUploads);
    this.tableRefreshInterval = 0;
  },
  methods: {
    async fetchStorages() {
      try {
        this.storages = (await StorageCollection.fetchAll()).array;
        this.selectedStorage = this.storages.find(
          (storage) => storage.user === this.currentUser.id
        );
      } catch (error) {
        console.log(error);
        this.newUploadError = true;
      }
    },
    async fetchProjects() {
      try {
        this.projects = (await ProjectCollection.fetchAll()).array;
      } catch (error) {
        console.log(error); // not mandatory for upload => only log error, no other action
      }
    },

    async refreshStatusSessionUploads() {
      const pendingStatus = [
        UPLOADED_FILE_STATUS.UPLOADED,
        UPLOADED_FILE_STATUS.TO_DEPLOY,
        UPLOADED_FILE_STATUS.UNCOMPRESSED,
        UPLOADED_FILE_STATUS.TO_CONVERT,
      ];

      let unfinishedConversions = false;
      let statusChange = false;

      try {
        await Promise.all(
          this.dropFiles.map(async (wrapper) => {
            if (wrapper.uploadedFile) {
              const oldStatus = wrapper.uploadedFile.status;
              if (!pendingStatus.includes(oldStatus)) {
                return;
              }

              await wrapper.uploadedFile.fetch();
              const status = wrapper.uploadedFile.status;
              if (status !== oldStatus) {
                statusChange = true;
              }
              if (pendingStatus.includes(status)) {
                unfinishedConversions = true;
              }
            }
          })
        );
      } catch (error) {
        console.log(error);
        return;
      }

      if (statusChange) {
        this.revision++;
      }

      if (unfinishedConversions) {
        clearTimeout(this.timeoutRefreshSessionUploads);
        this.timeoutRefreshSessionUploads = setTimeout(
          this.refreshStatusSessionUploads,
          constants.ONGOING_UPLOAD_REFRESH_INTERVAL
        );
      }
    },

    filesChange(files) {
      files.forEach((file) => {
        if (!file.processed) {
          file.processed = true;
          this.dropFiles.push({
            file,
            uploading: false,
            progress: 0,
            uploadedFile: null, // null if upload not finished, false if upload failed, UploadedFile instance if upload successful
            cancelToken: null,
          });
        }
      });
    },
    filesize(size) {
      return filesize(size, { base: 10 });
    },

    startUpload(fileWrapper) {
      if (fileWrapper.uploading || fileWrapper.uploadedFile !== null) {
        return;
      }

      const formData = new FormData();
      formData.append('files[]', fileWrapper.file);
      fileWrapper.cancelToken = axios.CancelToken.source();
      fileWrapper.uploading = true;
      axios
        .post(
          constants.CYTOMINE_UPLOAD_HOST + this.uri + '?' + this.queryString,
          formData,
          {
            headers: {
              authorization: `CYTOMINE ${this.currentUser.publicKey}:${this.signature}`,
              dateFull: this.signatureDate, // will replace actual date value, so that signature is valid
              'content-type-full': 'null', // will erase actual content-type value, so that signature is valid
            },
            onUploadProgress: (progress) => {
              fileWrapper.progress = Math.floor(
                (progress.loaded * 100) / progress.total
              );
            },
            cancelToken: fileWrapper.cancelToken.token,
          }
        )
        .then((response) => {
          fileWrapper.uploadedFile = new UploadedFile(
            response.data[0].uploadFile.attr
          );
          this.refreshStatusSessionUploads();
          this.revision++;
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.log(error);
            fileWrapper.uploadedFile = false;
          }
        })
        .finally(() => (fileWrapper.uploading = false));
    },
    startAll() {
      this.dropFiles.forEach((wrapper) => this.startUpload(wrapper));
    },
    /** @param {number} index */
    cancelUpload(index) {
      const fileWrapper = this.dropFiles[index];
      if (fileWrapper.cancelToken) {
        fileWrapper.cancelToken.cancel();
      }
      this.dropFiles.splice(index, 1);
    },
    cancelAll() {
      const nbFiles = this.dropFiles.length;
      let idx = 0;
      for (let i = 0; i < nbFiles; i++) {
        if (this.dropFiles[idx].uploadedFile !== null) {
          idx++;
        } else {
          this.cancelUpload(idx);
        }
      }
    },
    updatedTree() {
      this.revision++; // updating the table will result in new files objects => the uf details will also be updated
    },
    debounceSearchString: debounce(async function(value) {
      this.searchString = value;
    }, 500),
    hideFinished() {
      const nbFiles = this.dropFiles.length;
      let idx = 0;
      for (let i = 0; i < nbFiles; i++) {
        const uploadedFile = this.dropFiles[idx].uploadedFile;
        if (
          uploadedFile !== null &&
          this.finishedStatus.includes(uploadedFile.status)
        ) {
          this.cancelUpload(idx);
        } else {
          idx++;
        }
      }
    },
  },
};
</script>
